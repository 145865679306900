<div class="main-wrapper">
    <app-sidebar></app-sidebar>
    <div class="page-wrapper">
        <app-header></app-header>
        <main>
            <router-outlet></router-outlet>
        </main>
        <!-- <app-footer class="zi2"></app-footer> -->
    </div>
</div>
<!-- <mat-sidenav-container class="main-container">
    <mat-sidenav #sidenav role="navigation">
      <app-sidenav-list (sidenavClose)="sidenav.close()" (click)="sidenav.toggle()"></app-sidenav-list>
    </mat-sidenav>
    <mat-sidenav-content class="content ht-100v pd-0">
        <div class="docs-component-sidenav-content">
            <app-header class="sticky-header" (sidenavToggle)="sidenav.toggle()"></app-header>
            <div class="docs-component-sidenav-inner-content">
                <main class="docs-component-sidenav-body-content">
                    <app-sidebar></app-sidebar>
                    <router-outlet></router-outlet>
                </main>
                <app-footer class="zi2"></app-footer>
            </div>
        </div>
    </mat-sidenav-content>
  </mat-sidenav-container> -->