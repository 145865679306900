import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private login$: BehaviorSubject<any> = new BehaviorSubject([]);
  private header$: BehaviorSubject<any> = new BehaviorSubject([]);
  private side_toggle$: BehaviorSubject<any> = new BehaviorSubject([]);
  constructor() {}

  setLogin(user) {
    this.login$.next(user);
  }

  getLogin(): Observable<any> {
    return this.login$;
  }

  isAdmin(){
    return this.login$['_value'].role === 2 || JSON.parse(localStorage.getItem("trip-zolo-crm")).role === 2;
  }

  isSuperAdmin(){
    return this.login$['_value'].role === 1 || JSON.parse(localStorage.getItem("trip-zolo-crm")).role === 1;
  }

  isOperation(){
    return this.login$['_value'].role === 3 || JSON.parse(localStorage.getItem("trip-zolo-crm")).role === 3;
  }

  isCoordinator(){
    return this.login$['_value'].role === 4 || JSON.parse(localStorage.getItem("trip-zolo-crm")).role === 4;
  }

  isAgent(){
    return this.login$['_value'].role === 11 || JSON.parse(localStorage.getItem("trip-zolo-crm")).role === 11;
  }

  isGroundManager(){
    return this.login$['_value'].role === 12 || JSON.parse(localStorage.getItem("trip-zolo-crm")).role === 12;
  }

  isGroundOperation(){
    return this.login$['_value'].role === 13 || JSON.parse(localStorage.getItem("trip-zolo-crm")).role === 13;
  }

  isDriver(){
    return this.login$['_value'].role === 9
    || JSON.parse(localStorage.getItem("trip-zolo-crm")).role === 9
    || this.login$['_value'].role === 10
    || JSON.parse(localStorage.getItem("trip-zolo-crm")).role === 10;
  }

  header(action) {
    this.header$.next(action);
  }

  getheader(): Observable<any> {
    return this.header$.asObservable();
  }

  setSideToggle(action) {
    this.side_toggle$.next(action);
  }

  getSideToggle(): Observable<any> {
    return this.side_toggle$.asObservable();
  }
}
