import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { TourVehicleAllocationComponent } from 'src/app/coordinator/dialogs/tour-vehicle-allocation/tour-vehicle-allocation.component';
import { SnackBarService } from 'src/services/alert/snack-bar.service';
import { EndPointService } from 'src/services/end-point.service';
import { ConfirmationPreviewComponent } from '../dialog/confirmation-preview/confirmation-preview.component';

@Component({
  selector: 'app-confirmed-list',
  templateUrl: './confirmed-list.component.html',
  styleUrls: ['./confirmed-list.component.css']
})
export class ConfirmedListComponent implements OnInit {

  @ViewChild('paginator') paginator: MatPaginator;
  pagination = [];
  $searchTerm: Subject<string> = new Subject<string>();
  search = {
    search_term: ''
  }
  confirmations = [];
  confirmation_loader: boolean = false;
  search_spinner: boolean = false;
  delete_waiting = [];
  filter_date = [];
  coordinators = [];
  updateBtn = [];
  enquiryIndex: number;

  tour = {
    tour_status: 'upcoming_tour',
    month: 0,
    year: new Date().getFullYear()
  }

  constructor(
    private endpoint:EndPointService,
    private _snack_bar: SnackBarService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.getCoordinator();
    let pagination = {
      length: 0,
      pageIndex: 0,
      pageSize: 10,
      previousPageIndex: 0,
      term: this.search.search_term,
      tour_status: this.tour.tour_status,
      month: this.tour.month,
      year: this.tour.year,
    }
    this.getConfirmations(pagination);
  }

  getfilterData(event){
    event['term'] = this.search.search_term;
    event['tour_status'] = this.tour.tour_status;
    event['month'] = this.tour.month;
    event['year'] = this.tour.year;
    this.getConfirmations(event);
  }

  onSearch(event: any) {
    // this.places = [];
    if (this.$searchTerm.observers.length === 0) {
        this.$searchTerm.pipe(debounceTime(1000), distinctUntilChanged())
            .subscribe(term => {
              let search = {
                length: 0,
                pageIndex: 0,
                pageSize: 10,
                previousPageIndex: 0,
                term: term,
                tour_status: this.tour.tour_status,
                month: this.tour.month,
                year: this.tour.year,
              }

              this.getConfirmations(search);
              this.paginator.firstPage();
              this.search_spinner = false
            });
    }
    if(event.target.value.length >= 3){
      this.search_spinner = true;
      this.$searchTerm.next(event.target.value);
    }
    if(event.target.value === ''){
      this.search_spinner = true;
      this.$searchTerm.next(event.target.value);
    }
  }

  clearSearch(){
    this.search.search_term = ''
    let pagination = {
      length: 0,
      pageIndex: 0,
      pageSize: 10,
      previousPageIndex: 0,
      term: this.search.search_term,
      tour_status: this.tour.tour_status,
      month: this.tour.month,
      year: this.tour.year,
    }
    this.getConfirmations(pagination);
    this.paginator.firstPage();
  }

  getConfirmations(pagination){
    this.confirmation_loader = true;
      this.endpoint.getData('enquiries/confirmed/enquiry', pagination).subscribe(resp => {
        if (resp.status && resp.message == "success") {
            this.confirmations = resp.data;
            this.pagination = resp.meta;
            this.filter_date = resp.filter_date;
            this.confirmation_loader = false;
            return;
        }
        this._snack_bar.error(resp.message);
        this.confirmation_loader = false;
      }, err => {
        this._snack_bar.error('Error while fetching confirmations.');
        this.confirmation_loader = false;
      });
    }

    tourStatusOnChange(clear = false){
      let pagination = {
        length: 0,
        pageIndex: 0,
        pageSize: 10,
        previousPageIndex: 0,
        term: this.search.search_term,
        tour_status: this.tour.tour_status,
        month: (clear)?0: this.tour.month,
        year: this.tour.year,
      }
      this.getConfirmations(pagination);
    }

    getEnquiryIndex(i){
      this.enquiryIndex = i;
    }

    onAssignCoordinator(id, name){
        let data = {
          coordinator: id,
          tour_day_update: false,
          enquiry_code: this.confirmations[this.enquiryIndex]["enquiry_code"],
        }

      this.updateBtn[this.enquiryIndex] = true;
      this.endpoint.postData("enquiries", data).subscribe(
        (resp) => {
          if (resp.status && resp.message == 'success') {
            this._snack_bar.success("Successfully updated.");
            this.confirmations[this.enquiryIndex]["coordinator"] = {id: id, name: name}
            this.updateBtn[this.enquiryIndex] = false;
            return;
          }
          this._snack_bar.error(resp.message);
          this.updateBtn[this.enquiryIndex] = false;
        },
        (err) => {
          this._snack_bar.error("Error while update enquiry.");
          this.updateBtn[this.enquiryIndex] = false;
        }
      );
    }

    getCoordinator(){
      this.endpoint.getData("users/coordinators", {}).subscribe(
        (resp) => {
          if (resp.status && resp.message == 'success') {
            this.coordinators = resp.data;
          }
        },
        (err) => {
          this._snack_bar.error("Error while fetching enquiry coordinators.");
        }
      );
    }

    // viewConfirmation(confirmation){
    //   this.dialog.open(ConfirmationPreviewComponent, {panelClass: 'dialogPanel', data: confirmation})
    // }
}
