import { Location } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment.prod';
import { SnackBarService } from 'src/services/alert/snack-bar.service';
import { AuthService } from 'src/services/auth-user/auth.service';
import { EndPointService } from 'src/services/end-point.service';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
import { PermissionService } from 'src/services/permission/permission.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
@Output() public sidenavToggle = new EventEmitter();
  loader: boolean = false;
  count = 0;
  notifications = [];
  logged_in_user: any;
  visible_header: boolean = false;
  calculate_loader: boolean = false;
  contact_details = environment;
  isMinimized = true;
  permissions = {};
  moduleData = this.modulePermissionsService.moduleData;
  moduleDataArray = this.modulePermissionsService.moduleDataArray;
  accessMenuModel = [];

  constructor(
    private navctrl:Router,
    private location: Location,
    public AuthService: AuthService,
    private endpoint: EndPointService,
    private _snack_bar: SnackBarService,
    private dialog: MatDialog,
    private router: Router,
    private modulePermissionsService: PermissionService,
  ) {
    this.modulePermissionsService.getModulePermissions().subscribe(data => {
      if(data){
        this.permissions = data;
        this.hasAnyModulePermission();
      }
    })
  }

  ngOnInit(): void {
    this.AuthService.getheader().subscribe(value=>{
      if(this.location.path() !== "" && this.location.path().split('/')[1] !== "reset-password" && value){
        this.visible_header = true;
        // this.getNotifications();
        return;
      }
      if(typeof value !== 'boolean'){
        return;
      }
      this.visible_header = value;
    });



    this.AuthService.getSideToggle().subscribe(val => {
      this.isMinimized = val;
    });

    this.router.events.subscribe(val => {
        if(this.location.path().split('/')[1] == 'pdf'){
          this.visible_header = false;
          return;
        }
      });

    this.AuthService.getLogin().subscribe(value=>{
      this.logged_in_user = value;
    });
  }

  toggleMinimize() {
    this.isMinimized = !this.isMinimized;
    this.AuthService.setSideToggle(this.isMinimized);
  }

  public onToggleSidenav = () => {
    this.sidenavToggle.emit()
  }

  logout(){
    localStorage.removeItem('trip-zolo-crm');
    this.visible_header = false;
    // this.navctrl.navigate(['/']);
    window.location.href = "/";
    this.AuthService.setLogin(false);
  }

  getDepositPendingVerificationCount(){
    this.loader = true;
    this.endpoint.getData("accounts/pending-deposit-verifications", {}).subscribe(
      (resp) => {
        if (resp.status && resp.message == 'success') {
          this.count = resp.count;
          this.loader = false;
          return;
        }
        this.loader = false;
        this._snack_bar.error(resp.message);
      },
      (err) => {
        this.loader = false;
        this._snack_bar.error("Error while fetching deposit verification.");
      }
    );
  }

  getNotifications(){
    this.loader = true;
    this.endpoint.getData("notifications", {}).subscribe(
      (resp) => {
        if (resp.status && resp.message == 'success') {
          this.notifications = resp.data;
        }
      },
      (err) => {
        this.loader = false;
        this._snack_bar.error("Error while fetching notifications.");
      }
    );
  }

  viewNotification(event, notification_id, index){
    this._snack_bar.info(this.notifications[index].message);
    this.updateNotification(notification_id, index);
  }

  markAsReadNotification(event, notification_id, index){
    event.stopPropagation();
    let dialogRef = this.dialog.open(DeleteDialogComponent, {data:{message: "You want mark as read?"}});
    dialogRef.afterClosed().subscribe(resp => {
      if(resp === true){
        this.updateNotification(notification_id, index)
      }
    })
  }

  updateNotification(notification_id, index){
    this.endpoint.putData("notifications/"+notification_id+"/seen", {}).subscribe(
      (resp) => {
        if (resp.status && resp.message == 'success') {
          this.notifications.splice(index, 1);
        }
      },
      (err) => {
        this.loader = false;
        this._snack_bar.error("Error while seen notification.");
      }
    );
  }

  updateFinalCalculate(){
    this.calculate_loader = true;
    this.endpoint.postData("update-calculation", {}).subscribe(
      (resp) => {
        if (resp.status && resp.message == 'success') {
          this.calculate_loader = false;
          this._snack_bar.success("Successfully calculated.");
          return;
        }
        this.calculate_loader = false;
      },
      (err) => {
        this.calculate_loader = false;
        this._snack_bar.error("Error while update calculation.");
      }
    );
  }

  hasPermission(moduleName: string, subTitle: string, permission: string){
    if(this.permissions[moduleName]){
      let index = this.permissions[moduleName].findIndex(module => module.subTitle == subTitle );
      if(index >= 0){
         if(this.permissions[moduleName][index].permissions.includes(permission)){
          return true;
         }
      }
    }
  }

  hasAnyModulePermission(){
    Object.keys(this.moduleDataArray).forEach(mainModule => {
      this.moduleDataArray[mainModule].forEach(module => {
        if(Object.keys(this.permissions).includes(module)){
          this.accessMenuModel.push(mainModule);
        }
      });
    });
    this.modulePermissionsService.setFilterModulePermissions({"mainManu": this.accessMenuModel, 'manu': this.moduleDataArray, userPermissions: this.permissions });
    console.log(this.accessMenuModel);
    console.log(this.accessMenuModel['Products']);
    // Object.keys(this.permissions).forEach(permission => {
    //   console.log(permission);
    // });
    // let hasPermission  = this.moduleData.includes()

    // console.log(this.moduleDataArray);
  }
}
