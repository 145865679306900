<div class="content content-fixed">
  <div class="col-lg-12 col-xl-12 mg-t-10">
    <div class="actions-container">
      <h4>Confirmation List</h4>
      <div class="col-md-2">
        <mat-form-field class="form-field" >
          <mat-label>Tour<mat-icon>tour</mat-icon></mat-label>
          <mat-select [(ngModel)]="tour.tour_status" name="tour_status" #tour_status="ngModel" (selectionChange)="tourStatusOnChange(true)">
            <mat-option value="complete_tour">Complete tours</mat-option>
            <mat-option value="upcoming_tour">Upcoming</mat-option>
            <mat-option value="on_tour">On tours</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-2" *ngIf="filter_date">
        <mat-form-field class="form-field">
          <mat-label>Month<mat-icon>filter_alt</mat-icon></mat-label>
          <mat-select [(ngModel)]="tour.month" name="month" #month="ngModel" (selectionChange)="tourStatusOnChange()">
            <mat-option value="0">None</mat-option>
            <mat-option *ngFor="let month of filter_date.month_value; let i = index" [value]="filter_date.months[i]">{{month}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-2" *ngIf="filter_date">
        <mat-form-field class="form-field">
          <mat-label>Year<mat-icon>filter_alt</mat-icon></mat-label>
          <mat-select [(ngModel)]="tour.year" name="year" #year="ngModel" (selectionChange)="tourStatusOnChange()">
            <mat-option value="0">None</mat-option>
            <mat-option *ngFor="let year of filter_date.years; let i = index" [value]="year">{{year}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-3">
      <mat-form-field class="form-field">
        <mat-label>Search confirmation</mat-label>
        <input matInput type="text" autocomplete="off" name="search_term" #search_term="ngModel"
          [(ngModel)]="search.search_term" (keyup)="onSearch($event)" (paste)="onSearch($event)">
        <button mat-button mat-icon-button *ngIf="search_spinner" matSuffix><mat-icon><mat-spinner diameter="15"></mat-spinner></mat-icon></button>
        <button mat-button mat-icon-button matPrefix><mat-icon>search</mat-icon></button>
        <button mat-button mat-icon-button *ngIf="search.search_term && !search_spinner" (click)="clearSearch()" matSuffix
          aria-label="Clear">
          <mat-icon>close</mat-icon>
        </button>
        <mat-hint>Min 3 Characters</mat-hint>
      </mat-form-field>
    </div>
    </div>
    <mat-divider class="title-divider"></mat-divider>
    <div class="card mg-b-10">
      <div class="table-responsive">
        <!-- <button type="button" class="btn btn-xs btn-outline-dark" (click)="exportToExcel()">EXCEL</button> -->
        <table class="table table-dashboard mg-b-0">
          <thead>
            <tr>
              <th>Confirmed Date</th>
              <th>Enquiry Code</th>
              <th>Type</th>
              <th>Guest Name</th>
              <th>Travel From</th>
              <th>Travel to</th>
              <th>Handled by</th>
              <th>Confirmation</th>
              <th>Service Voucher</th>
              <th>Coordinator</th>
            </tr>
          </thead>
          <tbody>
          <tr>
            <td colspan="12" *ngIf="confirmation_loader"><button mat-button><mat-icon><mat-spinner diameter="20" color="accent"></mat-spinner></mat-icon>Loading...</button></td>
            <td colspan="12" *ngIf="!confirmation_loader && confirmations.length == 0">Confirmation not found...</td>
          </tr>
            <tr *ngFor="let confirmation of confirmations; let i = index">
              <td>{{confirmation.confirmed_package_costs && confirmation.confirmed_package_costs.created_at | date:"dd MMM, yyyy h:mm a"}}</td>
              <td>{{confirmation.enquiry_code}}</td>
              <td>{{confirmation.tour_type}}</td>
              <td>{{confirmation.travellers.name | titlecase}}</td>
              <td>{{confirmation.travellers.travel_date | date:"dd MMM, yyyy"}} </td>
              <td>{{confirmation.travellers.travel_date_to | date:"dd MMM, yyyy"}}</td>
              <td>{{confirmation.assigned_to.name}}</td>
              <td><a mat-button target="_blank()" href="/ground-manager/confirmation-view/{{confirmation.enquiry_code}}"><mat-icon>visibility</mat-icon></a></td>
              <td><a mat-button target="_blank()" href="/ground-manager/service-voucher/{{confirmation.enquiry_code}}"><mat-icon>note</mat-icon></a></td>
              <td>
                <a mat-button [matMenuTriggerFor]="menu" (click)="getEnquiryIndex(i)" *ngIf="!updateBtn[i]"><mat-icon>person_add</mat-icon> {{confirmation.coordinator && confirmation.coordinator.name}}</a>
                <mat-icon *ngIf="updateBtn[i]"><mat-spinner diameter="20" color="warn"></mat-spinner></mat-icon>
              </td>
            </tr>
          </tbody>
        </table>


        <mat-menu #menu="matMenu">
          <a mat-menu-item *ngFor="let coordinator of coordinators" (click)="onAssignCoordinator(coordinator.id, coordinator.name)">{{coordinator.name}}</a>
        </mat-menu>

        <mat-paginator #paginator *ngIf="pagination && pagination['paging']" [length]="pagination['paging'].total_count"
        showFirstLastButtons
              [previousPageIndex]="1"
              [pageSize]="10"
              [pageSizeOptions]="[10, 25, 50, 75, 100]"
              (page)="pageEvent = getfilterData($event)"
              >
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
