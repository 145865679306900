<mat-dialog-content class="position-relative">
  <!-- <mat-icon color="warn">warning</mat-icon> -->
  <div class="text-center">
    <img src="../../../assets/img/warning.png" alt="alert" style="height: 80px;">
    <p style="font-size: 20px; font-weight: 500; font-family: 'Roboto', sans-serif; margin-top: 10px;">{{message}}</p>
    <div>
      <a class="closeD" mat-icon-button color="{{data != null && data.extraData !=null ? data.extraData.second_button_color :'warn'}}" (click)="onDismiss()">
        <mat-icon>cancel</mat-icon></a>
      <!-- <button mat-mini-fab
          color="{{data != null && data.extraData !=null ? data.extraData.second_button_color :'warn'}}"
          (click)="onDismiss()">
          {{data != null && data.extraData !=null ? data.extraData.second_button_text: 'Cancel'}}
        </button> -->
    </div>
  </div>
  <div class="box bstate" *ngIf="holdBtn && data.status !== 2">
    <div class="text-center pt-2 pb-2">
      <span class="d-inline-block pr-3">Confirm Now</span> <mat-slide-toggle> <span class="d-inline-block pl-2">Hold this Booking</span></mat-slide-toggle>
    </div>
    <div class="d-flex align-items-center justify-content-center" style="border: 1px dashed #ccc; margin-bottom: 10px; background-color: rgb(105 144 183 / 19%); padding: 8px;">
      <p class="mr-2 mb-0"><b>Hold the booking</b></p>
      <div>
        <mat-form-field class="form-field" appearance="outline">
          <mat-label>Hold till</mat-label>
          <input matInput [matDatepicker]="hold_till" (click)="hold_till.open()"
          [(ngModel)]="hold.hold_date"
          name="hold_date"
          #hold_date="ngModel"
          required
          readonly>
          <mat-datepicker-toggle matSuffix [for]="hold_till"></mat-datepicker-toggle>
          <mat-datepicker #hold_till></mat-datepicker>
          <mat-error *ngIf="hold_date.invalid && (hold_date.dirty || hold_date.touched) && hold_date.errors?.required">
            Hold till date is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <!-- <div class="row" *ngIf="holdBtn && data.status !== 2">
      <div class="col-sm-12 col-md-12">
        <button mat-stroked-button class="hold-btn" [disabled]="hold.hold_date == ''" *ngIf="data.status !== 2" (click)="onHoldConfirmClick()">
          <mat-icon>timelapse</mat-icon> Hold
        </button>
      </div>
    </div> -->
    <!-- <div class="text-center">
      <button mat-raised-button
          color="{{data != null && data.extraData !=null ? data.extraData.second_button_color :'warn'}}"
          (click)="onDismiss()">
          {{data != null && data.extraData !=null ? data.extraData.second_button_text: 'Cancel'}}
        </button>
    </div> -->
    <!-- <div class="row">
      <div class="col-sm-3 col-md-3">
        <button mat-raised-button
          color="{{data != null && data.extraData !=null ? data.extraData.first_button_color :'primary'}}"
          (click)="onConfirmClick()">
          {{data != null && data.extraData !=null ? data.extraData.first_button_text: 'Yes'}}
        </button>
      </div>
      <div class="col-sm-3 col-md-3 margin-auto">
        <button mat-raised-button
          color="{{data != null && data.extraData !=null ? data.extraData.second_button_color :'warn'}}"
          (click)="onDismiss()">
          {{data != null && data.extraData !=null ? data.extraData.second_button_text: 'Cancel'}}
        </button>
      </div>
    </div> -->
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-raised-button
          color="{{data != null && data.extraData !=null ? data.extraData.first_button_color :'primary'}}"
          (click)="onConfirmClick()">
          {{data != null && data.extraData !=null ? data.extraData.first_button_text: 'Confirm Now'}}
  </button>
  <span class="d-inline-block hold ml-2" *ngIf="holdBtn && data.status !== 2">
      <button mat-stroked-button class="hold-btn" [disabled]="hold.hold_date == ''" *ngIf="data.status !== 2" (click)="onHoldConfirmClick()">
        <mat-icon>motion_photos_paused</mat-icon> Hold Now
      </button>
    </span>
</mat-dialog-actions>
