import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EndPointService {
  // apiUrl = 'http://localhost:8080/';
  // apiUrl = 'http://api.tripzolo.biz/';
  apiUrl = 'https://mecrm-api.mountainedge.co.in/';
  error_msg = "Oops! \nSomething went wrong.";

  constructor(private http: HttpClient, private route: Router) {}


  postData(endpoint, data):Observable<any>{
    // let token = "login";
    // if(endpoint !== 'users/login'){
    //   token = JSON.parse(localStorage.getItem('trip-zolo-crm')).token;
    // }
    // let headers = new HttpHeaders({
    //     'Authorization': 'Bearer '+ token,
    // });

    let token = "";
    // if(endpoint !== 'api-user/login' && endpoint !== 'users'){
      if(JSON.parse(localStorage.getItem('trip-zolo-crm')) !== null){
        token = JSON.parse(localStorage.getItem('trip-zolo-crm')).token;
      }
    // }
    let headers = new HttpHeaders({
      // 'Content-Type': 'application/json;multipart/form-data',
        'Authorization': 'Bearer '+ token,
    });

    return new Observable(observe => {
      this.http.post(this.apiUrl+endpoint, data, {headers: headers}).subscribe(resp => {
        observe.next(resp);
      },err => {
        if(err.status === 403 || err.status === 401){
          localStorage.removeItem('trip-zolo-crm');
          this.route.navigate(['/']);
          return;
        }
        err.message = this.error_msg;
        observe.next(err);
      });
    })
  }

  getData(endpoint, data):Observable<any>{
    if(localStorage.getItem('trip-zolo-crm') == null){
      return;
    }
    let token = JSON.parse(localStorage.getItem('trip-zolo-crm')).token;
    let headers = new HttpHeaders({
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': 'Bearer '+ token,
    });
    return new Observable(observe => {
      this.http.get(this.apiUrl+endpoint, {headers: headers, params: data}).subscribe(resp => {
        observe.next(resp);
      },error => {
        if(error.status === 403  || error.status === 401){
          localStorage.removeItem('trip-zolo-crm');
          this.route.navigate(['/']);
          return;
        }
        error.message = this.error_msg;
        observe.next(error);
      });
    });
  }

  putData(endpoint, data):Observable<any>{
    let token = JSON.parse(localStorage.getItem('trip-zolo-crm')).token;
    let headers = new HttpHeaders({
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': 'Bearer '+ token,
    });
    return new Observable(observe => {
      this.http.put(this.apiUrl+endpoint, data, {headers: headers}).subscribe(resp => {
        observe.next(resp);
      },err => {
        if(err.status === 403  || err.status === 401){
          localStorage.removeItem('trip-zolo-crm');
          this.route.navigate(['/']);
          return;
        }
        err.message = this.error_msg;
        observe.next(err);
      });
    })
  }

  deleteData(endpoint):Observable<any>{
    let token = JSON.parse(localStorage.getItem('trip-zolo-crm')).token;
    let headers = new HttpHeaders({
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': 'Bearer '+ token,
    });
    return new Observable(observe => {
      this.http.delete(this.apiUrl+endpoint, {headers: headers}).subscribe(resp => {
        observe.next(resp);
      },err => {
        if(err.status === 403  || err.status === 401){
          localStorage.removeItem('trip-zolo-crm');
          this.route.navigate(['/']);
          return;
        }
        err.message = this.error_msg;
        observe.next(err);
      });
    })
  }
}
