import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  private modulePermissions: BehaviorSubject<any> = new BehaviorSubject<any>('');
  private moduleFilterPermissions: BehaviorSubject<any> = new BehaviorSubject<any>('');

  moduleDataArray = {
    "Products": ["Itinerary", "Hotel", "Package Hotel", "Customised Package", "Ferry", "Place", "Markup"],
    "Reports": ["Sales", "Hotel Purchase", "Vehicle", "Ticket", "Agency Report", "Add New Agency", "Hotel Payment Report"],
    "Accounts": ["Deposit verification", "Credited Bank Statement", "HR", "Credit Note", "Invoice", "GST", "Vendor Registration", "Add Agency"],
    "Liability": ["Hotel Liability", "Additional Hotel Liability", "Vehicle & Ticket Liability", "Other Liability"],
    "Operation": ["Enquiry", "Confirmation", "Report", "Liability", "Hotel Inventory"],
    "Ground Staff": ["Coordinator Confirmation List", "Confirmation List", "Driver Tour List", "Logistic Management"],
  }

  moduleData = [
    {
      "menu" : "Products",
      "modules" : [
        {"title": "Itinerary", 'sub_title': ['New Itinerary', 'Itinerary List']},
        {"title": "Hotel", 'sub_title':  ['New Hotel','Hotel List']},
        {"title": "Package Hotel", 'sub_title':  ['New Package Hotel','Package Hotel List']},
        {"title": "Customised Package", 'sub_title':  ['Customised Package Form','Customised Package']},
        {"title": "Ferry", 'sub_title':  ['New Ferry Rate','Ferry Rate List']},
        {"title": "Place", 'sub_title':  ['Place']},
        {"title": "Markup", 'sub_title':  ['Markup']},
      ]
    },
    {
      "menu" : "Reports",
      "modules" : [
        {"title": "Sales", 'sub_title':  ['Sales']},
        {"title": "Hotel Purchase", 'sub_title':  ['Hotel Purchase']},
        {"title": "Vehicle", 'sub_title':  ['Vehicle']},
        {"title": "Ticket", 'sub_title':  ['Ticket']},
        {"title": "Agency Report", 'sub_title':  ['Agency Report', 'Add New Agency', 'View Agency']},
        {"title": "Hotel Payment Report", 'sub_title':  ['Hotel Payment Report']},
      ]
    },
    {
      "menu" : "Accounts",
      "modules" : [
        {"title": "Deposit verification", 'sub_title':  ['Deposit verification']},
        {"title": "Credited Bank Statement", 'sub_title':  ['Credited Bank Statement']},
        {"title": "HR", 'sub_title':  ['Employee List','Employee Salary']},
        {"title": "Credit Note", 'sub_title':  ['Credit Note']},
        {"title": "Invoice", 'sub_title':  ['Invoice','Invoice Request']},
        {"title": "GST", 'sub_title':  ['GST']},
        {"title": "Vendor Registration", 'sub_title':  ['Vendor List', 'Add Vendor']},
        {"title": "Add Agency", 'sub_title':  ['Add Agency']},
      ]
    },
    {
      "menu" : "Liability",
      "modules" : [
        {"title": "Hotel Liability", 'sub_title':  ['Hotel Liability']},
        {"title": "Additional Hotel Liability", 'sub_title':  ['Additional Hotel Liability']},
        {"title": "Vehicle & Ticket Liability", 'sub_title':  ['Vehicle & Ticket Liability']},
        {"title": "Other Liability", 'sub_title':  ['Other Liability']}
      ]
    },
    {
      "menu" : "Operation",
      "modules" : [
        {"title": "Enquiry", 'sub_title':  ['New Enquiry','Enquiry List']},
        {"title": "Confirmation", 'sub_title':  ['Confirmation List', 'Cancelled Confirmation']},
        {"title": "Report", 'sub_title':  ['Sales Report','Deposits','Statement','Invoices','Confirmation Report']},
        {"title": "Liability", 'sub_title':  ['Other Liability']},
        {"title": "Hotel Inventory", 'sub_title':  ['Hotel Inventory']},
        {"title": "Hotel Liability", 'sub_title':  ['Purchase Invoice']},
      ]
    },
    {
      "menu" : "Ground Staff",
      "modules" : [
        {"title": "Coordinator Confirmation List", 'sub_title':  ['Coordinator Confirmation List']},
        {"title": "Confirmation List", 'sub_title':  ['Confirmation List']},
        {"title": "Driver Tour List", 'sub_title':  ['Driver Tour List']},
        {"title": "Logistic Management", 'sub_title':  ['Logistic Management']}
      ]
    },
    {
      "menu" : "Dashboard",
      "modules" : [
        {"title": "Dashboard", 'sub_title':  ['Dashboard Confirmation Section', 'Dashboard Transaction History', 'Dashboard Enquiries', 'Dashboard Ops Team Performance', 'Dashboard Request & Approvals']},
      ]
    },
  ];

  getModulePermissions(): Observable<any> {
    return this.modulePermissions;
  }

  setModulePermissions(permissions): void {
    this.modulePermissions.next(permissions);
  }

  getFilterModulePermissions(): Observable<any> {
    return this.moduleFilterPermissions;
  }

  setFilterModulePermissions(permissions): void {
    this.moduleFilterPermissions.next(permissions);
  }
}
